import VideoMetadataInterface from '@app/modules/videos/interfaces/video-metadata.interface';
import { VideoPrivacyStatus } from '@videos/enums/video-privacy-status.enum';

export default class VideoMetadata implements VideoMetadataInterface {
    constructor(
        readonly videoId: string,
        readonly url: string,
        readonly title: string,
        readonly thumbnailUrl: string,
        readonly isEmbeddable: boolean,
        readonly privacyStatus: VideoPrivacyStatus
    ) {}

    static make(data: VideoMetadataInterface): VideoMetadata {
        return new VideoMetadata(
            data.videoId,
            data.url,
            data.title,
            data.thumbnailUrl,
            data.isEmbeddable,
            data.privacyStatus
        );
    }

    isPublic(): boolean {
        return this.privacyStatus === VideoPrivacyStatus.Public;
    }

    isPrivate(): boolean {
        return this.privacyStatus === VideoPrivacyStatus.Private;
    }

    isUnlisted(): boolean {
        return this.privacyStatus === VideoPrivacyStatus.Unlisted;
    }
}
