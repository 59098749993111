import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as Services from '@videos/services';
import { Components } from './components';
import { PlatformModule } from '@app/platform.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [...Components],
    imports: [CommonModule, FormsModule, PlatformModule, TranslateModule],
    providers: [...Object.values(Services)],
    exports: [...Components],
})
export class VideosModule {}
