<div class="app-grid video-preview" *ngIf="videoMetadata">
    <div class="columns-4 video-preview-column-left">
        <a [href]="videoMetadata.url" target="_blank">
            <img
                class="video-preview-thumbnail"
                [src]="videoMetadata.thumbnailUrl"
                alt="{{ 'videos.aVideoThumbnail' | translate }}"
            />
        </a>
    </div>

    <div class="columns-8 video-preview-column-right">
        <div class="video-preview-provider">{{ 'videos.youTubeVideo' | translate }}</div>
        <div class="video-preview-title" [title]="videoMetadata.title">{{ videoMetadata.title }}</div>
        <div class="video-preview-url" [title]="videoMetadata.url">
            <a [href]="videoMetadata.url" target="_blank">{{ videoMetadata.url }}</a>
        </div>

        <div *ngIf="showPrivacyStatus" class="video-preview-privacy-status margin-top-1">
            <div
                *ngIf="videoMetadata.isPublic()"
                class="public"
                [innerHTML]="'videos.thisVideoIsPublicWhichMeansItCanBeViewedByAnyone' | translate"
            ></div>

            <div
                *ngIf="videoMetadata.isPrivate()"
                class="private"
                [innerHTML]="'videos.thisVideoIsPrivateWhichMeansItCanOnlyBeViewedByItsOwner' | translate"
            ></div>

            <div
                *ngIf="videoMetadata.isUnlisted()"
                class="unlisted"
                [innerHTML]="'videos.thisVideoIsUnlistedWhichMeansItCanOnlyBeViewedByAnyoneWithADirectLink' | translate"
            ></div>
        </div>

        <div *ngIf="showEmbeddableStatus" class="video-preview-embeddable-status margin-top-1">
            <div
                *ngIf="videoMetadata.isEmbeddable"
                class="embeddable"
                [innerHTML]="'videos.thisVideoIsEmbeddableWhichMeansItCanBeDisplayedInHumi' | translate"
            ></div>

            <div
                *ngIf="!videoMetadata.isEmbeddable"
                class="not-embeddable"
                [innerHTML]="'videos.thisVideoIsNotEmbeddableWhichMeansItCannotBeDisplayedInHumi' | translate"
            ></div>
        </div>
    </div>
</div>
