<div class="video-lookup">
    <div>
        <mat-form-field class="width-full no-helpers margin-bottom-1">
            <mat-label>{{ 'videos.youTubeVideoUrl' | translate }}</mat-label>
            <input
                matInput
                #videoInput
                type="text"
                placeholder="https://www.youtube.com/watch?v="
                [(ngModel)]="videoUrl"
                (input)="handleChange(videoUrl)"
                (click)="videoInput.select()"
            />
        </mat-form-field>

        <mat-error *ngIf="lastError">
            <ng-container *ngIf="lastError?.code === 'invalid-url'"
                >{{ 'videos.oopsItLooksLikeThisIsAnInvalidUrl' | translate }}
            </ng-container>
            <ng-container *ngIf="lastError?.code === 'not-found'"
                >{{ 'videos.oopsWeCouldntFindAVideoAtThisUrl' | translate }}
            </ng-container>
            <ng-container *ngIf="lastError?.code === 'service-unavailable'"
                >{{ 'videos.weCantLookupVideoPreviewsRightNowPleaseTryAgainInAFewSeconds' | translate }}
            </ng-container>
        </mat-error>
    </div>

    <ng-content select=".info"></ng-content>

    <div class="flex justified margin-top-2">
        <mat-spinner *ngIf="isLoading" [diameter]="75"></mat-spinner>

        <video-preview
            *ngIf="videoMetadata"
            [videoMetadata]="videoMetadata"
            [showPrivacyStatus]="true"
            [showEmbeddableStatus]="true"
        ></video-preview>
    </div>
</div>
