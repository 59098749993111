import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { VideoPreviewService } from '@videos/services';
import VideoMetadata from '@videos/value-objects/video-metadata.vo';
import { VideoError } from '@videos/errors/video-error';
import VideoErrorInterface from '@videos/errors/video-error.interface';

@Component({
    selector: 'video-lookup',
    templateUrl: './video-lookup.template.html',
    styleUrls: ['./video-lookup.style.scss'],
})
export default class VideoLookupComponent implements OnChanges {
    @Input() videoUrl?: string;
    @Output() metaChange = new EventEmitter<VideoMetadata>();

    videoMetadata: VideoMetadata;
    isLoading = false;
    lastError?: VideoErrorInterface;

    throttleTimer: any;

    constructor(protected previewService: VideoPreviewService) {}

    async ngOnChanges() {
        if (this.videoUrl) {
            this.lookupVideoByUrl(this.videoUrl);
        }
    }

    async handleChange(url: string) {
        this.clearVideoMetadata();

        this.lastError = undefined;

        clearTimeout(this.throttleTimer);

        if (url) {
            this.throttleTimer = setTimeout(() => {
                this.lookupVideoByUrl(url);
            }, 250);
        }
    }

    async lookupVideoByUrl(url: string) {
        this.isLoading = true;

        this.lastError = undefined;

        try {
            const metadata = await this.previewService.lookupByUrl(url);

            this.setVideoMetadata(metadata);
        } catch (error: any) {
            if (error instanceof VideoError) {
                this.clearVideoMetadata();

                this.lastError = error;
            } else {
                throw error;
            }
        }

        this.isLoading = false;
    }

    clearVideoMetadata() {
        this.videoMetadata = undefined;

        this.metaChange.emit(this.videoMetadata);
    }

    setVideoMetadata(metadata: VideoMetadata) {
        this.videoMetadata = metadata;

        this.metaChange.emit(this.videoMetadata);
    }
}
