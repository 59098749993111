import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import VideoMetadata from '@app/modules/videos/value-objects/video-metadata.vo';
import VideoMetadataInterface from '@videos/interfaces/video-metadata.interface';
import VideoNotFoundError from '@videos/errors/video-not-found.error';
import InvalidVideoUrlError from '@videos/errors/invalid-video-url.error';
import VideoPreviewServiceUnavailableError from '@videos/errors/video-preview-service-unavailable.error';

interface VideoMetadataResponseInterface {
    data: {
        attributes: VideoMetadataInterface;
    };
}

@Injectable()
export default class VideoPreviewService {
    constructor(private http: HttpClient) {}

    async lookupByUrl(url: string): Promise<VideoMetadata> {
        try {
            const response = await this.http
                .post<VideoMetadataResponseInterface>('/v2/videos/preview', {
                    url,
                })
                .toPromise();

            return VideoMetadata.make(response.data.attributes);
        } catch (error: any) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 400:
                        throw new InvalidVideoUrlError(`Invalid video URL: ${url}`, url);
                    case 404:
                        throw new VideoNotFoundError(`Could not find metadata for this video: ${url}`, url);
                    case 503:
                        throw new VideoPreviewServiceUnavailableError(
                            `Video preview service currently unavailable.`,
                            url
                        );
                }
            }

            throw error;
        }
    }
}
