import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import VideoMetadata from '@videos/value-objects/video-metadata.vo';
import { VideoPreviewService } from '@videos/services';

@Component({
    selector: 'video-preview',
    templateUrl: './video-preview.template.html',
    styleUrls: ['./video-preview.style.scss'],
})
export default class VideoPreviewComponent implements OnInit, OnChanges {
    @Input() videoMetadata: VideoMetadata;
    @Input() videoUrl?: string;
    @Input() showPrivacyStatus: boolean = false;
    @Input() showEmbeddableStatus: boolean = false;

    constructor(protected previewService: VideoPreviewService) {}

    async ngOnInit() {
        if (this.videoUrl) {
            this.loadMeta();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.videoUrl) {
            this.loadMeta();
        }
    }

    private loadMeta() {
        this.previewService.lookupByUrl(this.videoUrl).then((meta) => (this.videoMetadata = meta));
    }
}
